@font-face {
    font-family: "HMSans-Regular";
    src: url("./../assets/fonts/HMSans-Regular.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-Bold";
    src: url("./../assets/fonts/HMSans-Bold.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-Italic";
    src: url("./../assets/fonts/HMSans-Italic.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-Light";
    src: url("./../assets/fonts/HMSans-Light.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-SemiBold";
    src: url("./../assets/fonts/HMSans-SemiBold.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-BoldItalic";
    src: url("./../assets/fonts/HMSans-BoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-SemiBoldItalic";
    src: url("./../assets/fonts/HMSans-SemiBoldItalic.otf") format("opentype");
}

@font-face {
    font-family: "HMSans-LightItalic";
    src: url("./../assets/fonts/HMSans-LightItalic.otf") format("opentype");
}
@font-face {
    font-family: "HM-Slussen-Extended-Headline-Semibold";
    src: url("./../assets/fonts/HM-Slussen-Extended-Headline-Semibold.otf") format("opentype");
}