body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* overflow-y: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.blueCardHeader {
  background: #91C9EE !important;
}

.storeTop5Metric {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  max-height: 40vh;
  padding: 1rem;
    margin-left: 20px;
      margin-right: 20px;
    display: flex;
      flex-direction: column;
    }

.metricText {
  font-size: 34px;
}
.lastChild {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: end;
}
.topMetricContainer {
  display: flex;
  padding: 0.8rem !important;
}
.storeMetricCircle {
  background: radial-gradient(50% 50% at 50% 50%, #F2EEEE 0%, #D4C3C3 100%);
  width: 7rem;
  border-radius: 50%;
  height: 7rem;
  margin: auto;
  text-align: center;
  display: flex;
  margin-bottom: 0;
}
.feedbackQR {
  width: 6% !important;
  vertical-align: text-bottom !important;
}
.feedbackfooterText {
  vertical-align: text-bottom !important;
  width: 100%;
  }
  
  .endFeedback {
    display: flex;
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  
  .homeSlideBGmob {
    display: none !important;
}
@media (max-width: 480px) {
  .topMetricContainer {
    display: block;
  }

  .storeMetricCircle {
    width: 4rem;
    height: 4rem;
  }

  .storeTop5Metric {
    margin-bottom: 15px !important;
    margin: 0;
    margin-left: 0;
    margin-right: 0;
  }
    .feedbackQR {
      width: 15% !important;
    }
  
    .endFeedback {
      display: block;
    }
  
    .homeSlideBGdesk {
      display: none !important;
    }
  
    .homeSlideBGmob {
      display: block !important;
    }
}
.storeMetricsCard {
  height: 100% !important;
}

.storeRow1 {
  min-height: 19vh;
}

.storeRow2 {
  min-height: 40vh;
}
.headerPaddingFix {
  padding: 0 !important;
}

@media only screen and (max-width: 768px) {
  .carousel .slider-wrapper {
    overflow-y: auto !important;
}
}

/* .carousel .slider-wrapper {
  overflow-y: auto !important;
} */