// Here you can add other styles
.background {
  background-image: url('../assets/images/background.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.wrapper {
  // background-image: url('../assets/images/homebg.png');
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-size: cover;
  background: radial-gradient(
    70.83% 85.87% at 34.46% 29.17%,
    #435268 0%,
    #1d3551 36.98%,
    #0d2337 66.84%,
    #002e31 100%
  );
}
.login-card {
  background-color: transparent;
  color: #cc071e !important;
  border: none !important;
}
.btn-login-button {
  background-color: #000000;
  color: #ffffff;
}
.bg-login {
  background-color: white !important;
  color: #000000;
  opacity: 0.8;
}
.vertically-center-div-key-metrics {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.ellipse {
  border: 3px solid #b5b1b1;
  height: 150px;
  width: 150px;
  border-radius: 50%;
}

.center-div-key-metrics {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #000;
}

.visitors-value-circle-key-metrics {
  width: 80px;
  display: flex;
  height: 80px;
  background: rgba(147, 198, 231, 1);
  color: #282828;
  opacity: 1;
  justify-content: center;
  text-align: center;
  align-items: center;
  box-shadow: 0px 0px 4px rgba(134, 134, 134, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: normal;
}

.icons-key-metrics {
  background: #4f8b79;
  transform: matrix(-0.95, 0.2, 0.31, 0.98, 0, 0);
}
.visitors-value-circle-key-metrics .start-100 {
  left: 50% !important;
}
.cart-value-circle-key-metrics .start-0 {
  left: 30% !important;
}

.cart-value-circle-key-metrics {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: #282828;
  background: rgba(179, 231, 224, 1);
  opacity: 1;
  box-shadow: 0px 0px 4px rgba(134, 134, 134, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(0.21, 0.97, 1, -0.15, 0, 0);
  font-size: 15px;
  font-weight: normal;
}

.common-box-key-metrics {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 23px;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  background-color: #ffffff;
}

.bg-key-metrics {
  background: transparent;
}
.app-logo {
  width: 200px;
  padding: 20px;
}
// .container { max-width: 100% !important; background:#000} 
@media (min-width: 1600px) {
  .container {
   // max-width: 1440px;
    max-width: 100%;
  }
}
@media (min-width: 1900px) {
  .container {
    //max-width: 1650px;
    max-width: 100%;
  }
}
@media (min-width: 2000px) {
  .container {
    //max-width: 1840px;
    max-width: 100%;
  }
}
@media (min-width: 2300px) {
  .container {
    //max-width: 1940px;
    max-width: 100%;
  }
}
@media (min-width: 2500px) {
  .container {
   // max-width: 2240px;
   max-width: 100%;
  }
}
@media (min-width: 3120px) {
  .container {
    //max-width: 3000px;
    max-width: 100%;
  }
}
@media (min-width: 5000px) {
  .container {
    //max-width: 4600px;
    max-width: 100%;
  }
}
@media (min-width: 6900px) {
  .container {
    //max-width: 6600px;
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .ellipse {
    height: 135px;
    width: 135px;
  }
  .cart-value-circle-key-metrics {
    width: 55px;
    height: 55px;
  }
  .visitors-value-circle-key-metrics {
    width: 80px;
    display: flex;
    height: 80px;
  }
}
@media screen and (max-width: 1600px) {
  .ellipse {
    height: 130px;
    width: 130px;
  }
  .cart-value-circle-key-metrics {
    width: 55px;
    height: 55px;
  }
  .visitors-value-circle-key-metrics {
    width: 80px;
    display: flex;
    height: 80px;
  }
}
@media screen and (min-width: 1600px) {
  .ellipse {
    height: 110px;
    width: 110px;
  }
  .cart-value-circle-key-metrics {
    width: 55px;
    height: 55px;
  }
  .visitors-value-circle-key-metrics {
    width: 80px;
    display: flex;
    height: 80px;
  }
}
