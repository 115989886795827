@import '@coreui/coreui/scss/coreui';
@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'custom';

html body {
    height: 100%;
    font-family: HMSans-Regular !important;
    scroll-behavior: smooth;
}

.carousel .control-dots {
  // position: fixed !important;
  // bottom: 85px !important;
  bottom: 70px !important;
  text-align: right !important;
  width:93% !important;
  display:none !important;
}
.carousel.carousel-slider .control-arrow:hover {
  background: none !important;
}
.carCont 
{ .carousel-status{
  display:none !important;
}
//   .carousel.carousel-slider{
//   margin-top: 60px !important
// }
}
.slider-wrapper {
  height: 90vh !important;
}